<template>
  <el-dialog
      v-bind="$attrs"
      width="75%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      v-on="$listeners"
      @open="onOpen"
      @close="onClose"
  >
    <div class="dialogView">
      <el-scrollbar style="height: 100%">
        <div class="fileList">
          <el-tooltip placement="bottom-start" v-for="item in inquiryData.bom">
            <template slot="content">
              <el-link style="color: #FFFFFF" :href="`https://view.officeapps.live.com/op/view.aspx?src=${item.url}`" target="_blank">预览</el-link>
              <el-link style="color: #FFFFFF;margin-left: 10px" :href="item.url" target="_blank">下载</el-link>
            </template>
            <el-button type="text" >{{item.name}}</el-button>
          </el-tooltip>
        </div>
        <h2>报价 - {{inquiryData.currency}}</h2>
        <div class="tableView">
          <el-table :data="items" border style="flex: initial;width: auto">
            <el-table-column label="产品" width="250">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="数量" width="150">
              <template slot-scope="scope">
                <el-input type="number" :min="0" v-model="scope.row.num"></el-input>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header" slot-scope="scope">
                <span style="color:#F56C6C">系统单价({{ systemCurrency }})</span>
              </template>
              <template slot-scope="scope">
                <el-input :min="0.0" v-model="scope.row.system_price"></el-input>
<!--                <span>{{scope.row.system_price}}</span>-->
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template slot="header" slot-scope="scope">
                <span style="color:#409EFF">用户单价({{inquiryData.currency}})</span>
              </template>
              <template slot-scope="scope">
                <el-input  :min="0.0" v-model="scope.row.price"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="批发价" width="250">
              <template slot-scope="scope">
                <div v-for="item in scope.row.wholesale_arr" style="display: flex;align-items: center;justify-content: space-between">
                  <span>{{item.number}}</span>
                  <span>{{item.price}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" width="240">
              <template slot-scope="scope">
                <el-input type="textarea" :rows="1" v-model="scope.row.seller_notes"></el-input>
              </template>
            </el-table-column>
          </el-table>
          <el-button-group style="margin-left: 10px">
            <el-button type="primary" icon="el-icon-plus" @click="addTableItem"/>
            <el-button type="danger" icon="el-icon-minus" @click="delTableItem"/>
          </el-button-group>
        </div>
        <h2>运费</h2>
        <el-input type="number" :min="0.0" v-model="shipping_fee" placeholder="请输入运费"></el-input>
        <h2>快递公司</h2>
        <el-select v-model="shipping_company" placeholder="请选择快递公司">
          <el-option v-for="item in shippingList" :value="item.id" :label="item.name"></el-option>
        </el-select>
        <h2>附言</h2>
        <el-input type="textarea" :rows="4" v-model="notes" placeholder="请输入附言"></el-input>
<!--        <h2>链接</h2>-->
<!--        <el-checkbox>售后服务</el-checkbox>-->
<!--        <el-checkbox>退换货政策</el-checkbox>-->
      </el-scrollbar>

    </div>
    <div slot="footer">
      <el-button @click="close">
        取消
      </el-button>
      <el-button type="primary" @click="handelConfirm">
        确定
      </el-button>
    </div>


  </el-dialog>
</template>

<script>
import {apiCreateQuote, apiGetExpressList} from "@/request/api";

export default {
  name: "createQuotation",
  props:{
    inquiryData:{
      type:Object,
      default(){
        return {}
      }
    },
    systemCurrency:{
      type:String,
      default() {
        return 'USD';
      }
    }
  },
  data(){
    return{
      items:[],
      shipping_fee:'',
      shipping_company:'',
      notes:'',
      shippingList:[]
    }
  },
  methods:{
    getShppingList(){
      apiGetExpressList().then(res=>{
        this.shippingList = res.data;
      })
    },
    onOpen(){
      this.getShppingList()
      var list = []
      this.inquiryData.goods.forEach(item => {
        var obj = {
              part: item.sku,
              price: item.price,
              sku: item.sku,
              cond: item.cond,
              num: item.num,
              seller_notes: item.note,
              system_price:item.system_price,
              wholesale_arr:item.wholesale_arr
            }
        list.push(obj)
      })
      this.items = list
    },
    onClose(){},
    close(e) {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      var sss = false
      this.items.forEach(item => {
        if(!item.part || !item.price){
          sss = true
        }
      })
      if(sss) {
        this.$message.error('请完善报价表')
        return
      }
      if(!this.shipping_fee){
        this.$message.error('请设置运费')
        return
      }
      var data = {
        inquiry_id:this.inquiryData.id,
        shipping_fee:this.shipping_fee,
        shipping_company:this.shipping_company,
        notes:this.notes,
        items:this.items,
        email:this.inquiryData.email,
        user_id:this.inquiryData.user_id,
      }
      apiCreateQuote(data).then(res=>{
        if(res.code == 200){
          this.items = []
          this.shipping_fee = ''
          this.shipping_company = ''
          this.notes = ''
          this.$emit('success')
          this.close()
        }else{
          this.$message.error(res.message)
        }
      })

    },
    addTableItem(){
      this.items.push({
        part: '',
        price: 0,
        sku: '',
        cond: 'new',
        num: 0,
        seller_notes: '',
        system_price:0,
        wholesale_arr:[]
      })
    },
    delTableItem(){
      this.items.pop()
    }
  }
}
</script>

<style scoped>
.dialogView {
  height: 45vh;
}
.tableView {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.fucView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>

<template>
  <div class="page">
    <el-row>
      <el-col :span="18">
        <inquiryTableList :orderList="orderList" type="detail"/>
        <el-card style="margin-top: 10px">
          <div class="chatScrollbar">
            <div v-for="(item, index) in inquiryDetail.chat" :key="index">
              <div class="chat-item" :class="{'chat-item-user': item.is_user}">
                <img src="@/assets/Whatsachip Psd.png" style="width: 60px;height: 60px;border-radius: 10px"/>
                <div>
                  <div class="bottom">
                    <span>
                      {{item.create_at | formatDate}}
                      <i class="el-icon-user-solid" v-if="item.user_read && !item.is_user" style="margin-left: 5px"></i>
                      <i class="el-icon-success" v-if="item.is_email && !item.is_user"
                      style="margin-left: 5px"></i>
                    </span>
                    <span v-if="item.is_user">，{{item.extra_info.ip}} {{item.extra_info.addr}} {{item.extra_info.os}}</span>
                  </div>
                  <el-card class="chat-item-content">
                    <div v-html="item.content"></div>
                  </el-card>

                </div>

              </div>
            </div>
          </div>
          <div class="reqlyBtnList">
            <el-dropdown @command="quickReplyHandle" style="margin-right: 10px">
              <el-button >
                快捷回复模板<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in quickReplyList" :command="item.id">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown @command="quickLinkHandle" style="margin-right: 10px">
              <el-button >
                快捷链接<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in quickLinkList" :command="item.url">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown @command="quoteListHandle" style="margin-right: 10px">
              <el-button >
                报价单<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in quoteList" :command="item">{{item.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="selectedQuote">
              <div class="selectedQuoteItem" v-for="(item,index) in selectedQuote">
                <span style="margin-right: 10px">{{item.name}}</span>
                <i class="el-icon-close" style="cursor: pointer" @click="selectedQuote.splice(index)"></i>
              </div>
            </div>
          </div>
          <tinymce v-model="replyContent" ref="tinymce" placeholder="请输入内容" :height="300" :width="870" />
          <el-button :disabled="replyContent.length == 0" @click="replyHandle">回复</el-button>
        </el-card>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-card>

        </el-card>
        <div class="fucView">
          <el-button @click="showCreateQuotation = true">生成报价表</el-button>
          <el-button @click="showCreateOrder = true">创建订单</el-button>
          <el-button @click="">关闭</el-button>
          <el-button @click="">转询价</el-button>
          <el-button @click="">给用户打标签</el-button>
        </div>
      </el-col>
    </el-row>
    <createQuotation @success="getQuoteList" :visible.sync="showCreateQuotation" :inquiryData="inquiryDetail.info" :systemCurrency="inquiryDetail.system_currency" title="生成报价表"/>
    <el-dialog
        title="提示"
        :visible.sync="showCreateOrder"
        width="30%"
        @close="onClose"
        @open="onOpen">
        <el-form>
          <el-form-item label="订单来源">
            <el-select v-model="from.type" placeholder="请选择订单来源">
              <el-option label="报价单转订单" :value="1"></el-option>
              <el-option label="询盘单转订单" :value="2"></el-option>
<!--              <el-option label="手动录入" :value="4"></el-option>-->
            </el-select>
          </el-form-item>
          <el-form-item label="报价单" v-if="from.type == 1">
            <el-select v-model="from.quotation_id" placeholder="请选择报价单">
              <el-option v-for="item in quoteList" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运费" v-if="from.type == 2">
            <el-input type="number" :min="0.0" v-model.number="from.logistics_fee"></el-input>
          </el-form-item>
          <el-form-item label="备注" v-if="from.type == 2">
            <el-input type="textarea" :rows="4" v-model="from.remarks"></el-input>
          </el-form-item>
        </el-form>
      <span>创建成功后，自动通知用户</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onClose">取 消</el-button>
          <el-button type="primary" @click="toCreateInquiryOrder">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import createQuotation from './createQuotation'
import inquiryTableList from './components/inquiryTableList'
import {
  apiCreateOrder,
  apiGetInquiryDetail,
  apiGetQuickLinkList, apiGetQuoteHtml,
  apiGetQuoteList,
  apiGetReplyTemplate,
  apiReplyInquiry
} from "@/request/api";
export default {
  name: "inquiryDetail",
  components: {
    inquiryTableList,
    createQuotation
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  data(){
    return {
      id:this.$route.query.id,
      inquiryDetail:{},
      replyContent:'',
      quickLinkList:[],
      quickReplyList:[],
      quoteList:[],
      showCreateQuotation:false,
      orderList:[],
      showCreateOrder:false,
      selectedQuote:[],
      from:{
        type:1,
        quotation_id:'',
        remarks:'',
        logistics_fee: 0
      },
    }
  },
  created() {
    this.getData()
    this.getQuickLink()
    this.getQuickReply()
    this.getQuoteList()
  },
  methods:{

    quoteListHandle(command){
     var isSet =  this.selectedQuote.some(item => {
        return item.id == command.id
      })
      if(!isSet){
        this.selectedQuote = [command]
        apiGetQuoteHtml({id:command.id}).then(res=>{
          this.$refs.tinymce.inputContent(res.data)
        })
      }
    },
    getQuoteList(){
      apiGetQuoteList({
        id:this.id
      }).then(res=>{
        if(res.code == 200){
          this.quoteList = res.data
        }
      })
    },
    quickLinkHandle(command){
      var str = `<a href="${command}" target="_blank" rel="noopener">${command}</a>`
      this.$refs.tinymce.inputContent(str)
    },
    quickReplyHandle(command){
      apiGetReplyTemplate({id:command}).then(res=>{
        if(res.code == 200){
          this.$refs.tinymce.inputContent(res.data.content)
        }
      })
    },
    getQuickLink(){
      apiGetQuickLinkList().then(res=>{
        if(res.code == 200){
          this.quickLinkList = res.data
        }
      })
    },
    getQuickReply(){
      apiGetReplyTemplate().then(res=>{
        if(res.code == 200){
          this.quickReplyList =res.data
        }
      })
    },
    getData(){
      apiGetInquiryDetail({id:this.id}).then(res=>{
        if(res.code == 200){
          this.inquiryDetail = res.data
          this.orderList = [res.data.info]
        }
      })
    },
    replyHandle(){

      var data = {
        id:this.id,
        content:this.replyContent,
        'quotation_id':this.selectedQuote[0].id
      }
      apiReplyInquiry(data).then(res =>{
        if(res.code == 200){
          this.inquiryDetail.chat.push(res.data)
          this.replyContent = ''
          this.selectedQuote = []
        }
      })
    },
    onClose(){
      this.from ={
        type:1,
        quotation_id:''
      }
      this.showCreateOrder = false
    },
    onOpen(){
      this.from.quotation_id = this.quoteList[0].id || 0
    },
    toCreateInquiryOrder(){
      var data = {
        type:this.from.type,
      }
      if(this.from.type == 1){
        if(!this.from.quotation_id){
          this.$message({
            message: '请选择报价单',
            type: 'warning'
          });
          return false
        }
        data.quotation_id = this.from.quotation_id
      }
      if(this.from.type == 2){
        data.inquiry_id = this.inquiryDetail.info.id
        data.logistics_fee = this.from.logistics_fee
        data.remarks = this.from.remarks
      }
      apiCreateOrder(data).then(res => {
        if(res.code == 200){
          this.onClose()
          if(this.from.type == 1 || this.from.type == 2){
            this.$router.push({
              path:'/order/orderDetail',
              query:{
                id:res.data.order.id
              }
            })
          }
          if(this.from.type == 4) {
            this.$router.push({
              path:'/inquiry/inquiryCreateOrder',
              query:{
                id:res.data.order.id
              }
            })
          }
          this.from = {
            type:1,
            quotation_id:'',
            remarks:'',
            logistics_fee:0
          }
        }else{
          this.$message.error(res.message)
        }

      })

    }
  }
}
</script>

<style lang="less" scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  //margin: 20px;
}
.fucView {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.el-button{
  margin:0;
  margin-top: 5px;
}
.reqlyBtnList {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-top: 1px solid #ebeef5;
  //display: flex;
  //align-items: center;
  .selectedQuote {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    flex-direction: column;
  }
}

.chatScrollbar {
  width: 100%;
  .chat-item {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-left: 70px;
    .chat-item-content {
      margin:0 10px;
      //padding: 10px;

    }
    .bottom {
      //display: flex;
      //flex-direction: column;
      //align-items: flex-start;
      font-size: 14px;
      color: #909399;
      padding: 0 10px;
    }
  }
  .chat-item-user {
    flex-direction: row;
    padding: 0 10px;
    padding-right: 70px;
  }
}
</style>

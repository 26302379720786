<template>
  <div class="orderTable">
    <div class="tableHeader">
      <div class="tableHeader__item" style="width: 220px">
        <span>商品信息</span>
      </div>
      <div class="tableHeader__item" style="text-align: right;width: 60px">
        <span>数量/单价</span>
      </div>
      <div class="tableHeader__item" style="text-align: center;width: 220px">
        <span>买家信息</span>
      </div>
      <div class="tableHeader__item" style="text-align: center;width: 60px" v-if="type == 'list'">
        <span>操作</span>
      </div>
    </div>
    <div class="tableBody">
      <div class="orderItem" v-for="(item, index) in orderList">
        <div class="orderHeader">
          <div class="headerLeft">
            <span>系统编号：<el-button type="text" @click="toOrderDetail(item.id)">{{item.inquiry_sn}}</el-button></span>
            <span>状态：{{item.state_text}}</span>
            <span v-if="item.extra_info.length > 0">IP：{{item.extra_info[0].value}}</span>
          </div>
          <div class="headerRight">
            <span>更新时间：{{item.update_at | formatDate}}</span>
          </div>
        </div>
        <div class="order">
          <div class="tableBody__item" style="width: 220px">
            <template v-if="item.goods.length > 0">
              <div class="goodsItem" v-for="goodsItem in item.goods">
                <div class="goodsItem__info">
                  <div class="goodsItem__info__title">{{goodsItem.sku}}</div>
                </div>
              </div>
            </template>

            <template v-if="item.bom.length > 0">
              <div class="goodsItem" v-for="goodsItem in item.bom">
                <div class="goodsItem__info">
                  <el-tooltip placement="bottom-start">
                    <template slot="content">
                      <el-link style="color: #FFFFFF" :href="`https://view.officeapps.live.com/op/view.aspx?src=${goodsItem.url}`" target="_blank">预览</el-link>
                      <el-link style="color: #FFFFFF;margin-left: 10px" :href="goodsItem.url" target="_blank">下载</el-link>
                    </template>
                    <el-button type="text" >{{goodsItem.name}}</el-button>
                  </el-tooltip>

                </div>
              </div>
            </template>

          </div>
          <div class="tableBody__item" style="width: 60px">
            <template v-if="item.goods.length >0">
              <div class="goodsPriceItem" v-for="goodsItem in item.goods">
                <div class="goodsPriceItem__info">
                  <span class="goodsItem__info__unitPrice">单价：￥{{goodsItem.price}}，</span>
                  <span class="goodsItem__info__count">采购{{goodsItem.num}}件</span>
                </div>
              </div>
            </template>
            <template v-if="item.bom.length  > 0">
              <div class="goodsPriceItem" v-for="goodsItem in item.bom">
                <div class="goodsPriceItem__info">
                </div>
              </div>
            </template>

          </div >
          <div class="tableBody__item" style="width: 220px">
            <div class="customerInfo" v-if="item.user_id">
              <span class="customerInfoName">会员：{{item.user_card.base.name}}</span>
              <span class="customerInfoHistoryCount">历史成交单数：{{item.user_card.paid.pay_times}}</span>
              <span class="customerInfoHistoryPaid">累计总金额：{{item.user_card.paid.total_money}}</span>
              <span class="customerInfoLabel">用户标签：{{item.user_card.tag}}</span>
            </div>
          </div>
          <div class="tableBody__item" style="width: 60px" v-if="type == 'list'">
            <div class="orderFuc">
              <el-button type="text" @click="toOrderDetail(item.id)">查看信息</el-button>
              <el-button type="text" @click="closeOrder(item.id)">关闭</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "inquiryTableList",
  props: {
    orderList:{
      type:Array,
      default:[]
    },
    type:{
      type:String,
      default:"list"
    },
  },
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    }
  },
  data(){
    return{
    }
  },
  methods:{
    toOrderDetail(id){
      this.$router.push({
        path:'/inquiry/inquiryDetail',
        query:{
          id:id
        }
      })
    },
    closeOrder(id){

    }
  }
}
</script>

<style lang="less" scoped>
.orderTable{
  width:100%;
  display: flex;
  flex-direction: column;
.tableHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #ebeef5;
.tableHeader__item {
  padding: 12px 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
  flex-grow: 1;
}
.tableHeader__item~.tableHeader__item {
  border-left: 1px solid #ebeef5;
}
}
.tableBody {
  width:100%;
  display: flex;
  flex-direction: column;
.orderItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border: 1px solid #ebeef5;
.orderHeader,.orderFooter {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 35px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
span ~ span {
  margin-left: 10px;
}
}
.order {
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
.tableBody__item {
  min-height: 70px;
  flex-grow: 1;
}
.tableBody__item~.tableBody__item {
  border-left: 1px solid #ebeef5;
}
.goodsItem {
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
.goodsItem__info {
  width: calc(100% - 85px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 18px;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
.goodsItem__info__title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
}
}
.goodsItem~.goodsItem {
  border-top: 1px solid #ebeef5;
}
.goodsPriceItem {
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #909399;
  font-weight: bold;
.goodsItem__info__unitPrice {
  margin-top: 10px;
}
}
.goodsPriceItem~.goodsPriceItem {
  border-top: 1px solid #ebeef5;
}
.orderPriceInfo,.orderDefendRights,.customerInfo,.merchant,.orderState,.orderFuc {
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  color: #909399;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
}
.customerInfo{
  align-items: flex-start;
  justify-content: flex-start;
.customerInfoName {
  font-size: 14px;
  line-height: 24px;
}
.customerInfoAddress {
  width: 100%;
}
.customerInfoPhone {
  margin-top: 10px;
}
.customerInfoEmail {
  margin-top: 10px;
}
}
}
}
}
}
</style>